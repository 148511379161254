import React from 'react';

const Logout = React.lazy(() => import('../screens/logout'));
const TransactionDetail = React.lazy(() => import('../screens/transaction-detail'));
const Transactions = React.lazy(() => import('../screens/transactions'));
const Report = React.lazy(() => import('../screens/report'));
const Reports = React.lazy(() => import('../screens/reports'));
const Probative = React.lazy(() => import('../screens/probative'));

export const ROUTE_LOGOUT = '/logout';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_TRANSACTIONS = '/transactions';
export const ROUTE_REPORT = '/report';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_PROBATIVE = '/probative';

export const ROUTE_TRANSACTION_DETAIL_BASE = '/transactions';
export const ROUTE_TRANSACTION_DETAIL_PLACEHOLDER = `${ROUTE_TRANSACTION_DETAIL_BASE}/:transactionId`;

export const DEFAULT_ROUTE = '/';
export const DEFAULT_HOME_ROUTE = ROUTE_TRANSACTIONS;


export const getRoutesHandlers = (t) => [
  {
    Component: Logout,
    menuItem: false,
    route: ROUTE_LOGOUT,
    title: t('Logout'),
  },
  {
    Component: TransactionDetail,
    menuItem: false,
    route: ROUTE_TRANSACTION_DETAIL_PLACEHOLDER,
    title: t('Transaction'),
  },
  {
    Component: Transactions,
    menuItem: true,
    iconType: 'card',
    route: ROUTE_TRANSACTIONS,
    title: t('Transactions'),
  },
  {
    Component: Probative,
    menuItem: false,
    route: ROUTE_PROBATIVE,
    title: t('BodyEvidence'),
  },
  {
    Component: Report,
    menuItem: false,
    route: ROUTE_REPORT,
    title: t('Report'),
  },
  {
    Component: Reports,
    menuItem: true,
    iconType: 'cloud-download',
    route: ROUTE_REPORTS,
    title: t('Reports'),
  },
]
