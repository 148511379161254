const allowedChars = ['_', '.', '-'];

const validateJustAlphanumeric = (errorMessage = 'Digite apenas letras, números e os caracteres especiais permitidos.') =>
  () => ({
    validator(_, value) {
      if (value) {
        const allowedCharsRegex = allowedChars.map(char => '\\' + char).join('');
        const regexPattern = `^[a-zA-Z0-9${allowedCharsRegex}]+$`;
        console.log('regexPattern', regexPattern);
        if (!new RegExp(regexPattern).test(value)) {
          return Promise.reject(new Error(errorMessage));
        }
      }
      return Promise.resolve();
    }
  });

export default validateJustAlphanumeric;
