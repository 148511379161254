import { getAccessTokenFromStorage } from '@frontend/shared-context';
import { debounce, fetchIntercept } from '@frontend/shared-utility';

export const generateProbative =  debounce(async (transactionId) => {
  const response = await fetchIntercept(
    `${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/${transactionId}/probative`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${getAccessTokenFromStorage()}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Response not ok');
  }
});

export const getProbativeLink =  debounce(async (transactionId, fileId) => {
  const response = await fetchIntercept(
    `${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/${transactionId}/probative/link?fileID=${fileId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${getAccessTokenFromStorage()}`,
        'content-type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return await response.json();
});
