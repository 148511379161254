import { useState, useEffect } from 'react';
import {
  eventEmitter,
  emitterEvents,
} from '@frontend/shared-utility';
import { authorize } from '@frontend/shared-context';
import { ERROR_MESSAGE } from '../../utils/constants';

const FIVE_SECONDS_TIMEOUT = 5000;

export const useError401Modal = () => {
  const [showIdentityAuthorizeModal, setShowIdentityAuthorizeModal] =
    useState(false);

  useEffect(() => {
    const showIdentityAuthorizeModalListener = (content) => {
      if (typeof content === 'boolean') {
        setShowIdentityAuthorizeModal(content);
      } else {
        // Fallback in case the event sent ins't what we're expecting to handle the modal (boolean)
        throw new Error(ERROR_MESSAGE.INVALID_EVENT)
      }
    };

    eventEmitter.on(
      emitterEvents.IDENTITY_AUTHORIZE_MODAL_OPEN_EVENT,
      showIdentityAuthorizeModalListener
    );

    return () => {
      eventEmitter?.off?.(
        emitterEvents.IDENTITY_AUTHORIZE_MODAL_OPEN_EVENT,
        showIdentityAuthorizeModalListener
      );
    };
  }, [showIdentityAuthorizeModal]);

  useEffect(() => {
    if (showIdentityAuthorizeModal) {
      const authorizeTimeout = setTimeout(() => {
        authorize();
      }, FIVE_SECONDS_TIMEOUT);
      return () => {
        clearTimeout(authorizeTimeout);
      };
    }
  }, [showIdentityAuthorizeModal]);

  return [showIdentityAuthorizeModal, setShowIdentityAuthorizeModal];
};
