import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import LAUNCH_DARKLY_CONFIG from './launch-darkly-config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './app/app';
import { initI18n } from '@frontend/feature-i18n';
import { resourcesI18n } from '@frontend/pay/shared-defaults';

initI18n(resourcesI18n);

const rootDiv = document.getElementById('root-unico-idpay-issuer');

const renderWaitingFeatureFlags = async (root) => {
  (async () => {
    const LDProvider = await asyncWithLDProvider(LAUNCH_DARKLY_CONFIG);

    root.render(
      <StrictMode>
        <BrowserRouter>
          <LDProvider>
            <App />
          </LDProvider>
        </BrowserRouter>
      </StrictMode>
    );
  })();
};

const renderApp = (rootDivElement, _settings) => {
  let root;

  if (rootDivElement) {
    root = ReactDOM.createRoot(rootDivElement);
  } else {
    root = ReactDOM.createRoot(rootDiv);
  }

  renderWaitingFeatureFlags(root);
};

if (rootDiv) {
  renderApp();
}

window.renderUnicoIDPayEcommerceApp = renderApp;
