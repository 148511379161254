import { debounce, fetchIntercept } from '@frontend/shared-utility';
import { HttpError } from '@frontend/feature-error-handling';

export const getProfiles = debounce(async (accessToken, enableErrorBoundary) => {
  const isErrorBoundaryEnabled = enableErrorBoundary === undefined ? false : enableErrorBoundary;

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}${process.env.NX_GET_PROFILES_PATH}`, {
    method: 'GET',
    headers: { authorization: `Bearer ${accessToken}` }
  }, isErrorBoundaryEnabled);

  if (!response.ok) {
    if (isErrorBoundaryEnabled) {
      await HttpError.handleStatusCode(response)
    }

    throw new Error('Response not ok')
  }

  return await response.json();
});
