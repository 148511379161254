import {
  debounce,
  fetchIntercept,
} from '@frontend/shared-utility';
import { getAccessTokenFromStorage } from '@frontend/shared-context';
import { PROFILE_TYPE_ORGANIZATION } from 'libs/shared-context/src/lib/context/defaults/profile-types';

export const getReports = debounce(
  async ({ pageSize: size, current }, { filters }) => {
    const { profile } = filters;

    let filteredCompanies;

    if (profile.selectedProfileType === PROFILE_TYPE_ORGANIZATION) {
      filteredCompanies = profile.selectedProfile.companies.map(({ id }) => id);
    } else if (profile.selectedProfile) {
      filteredCompanies = [profile.selectedProfile.id];
    } else return;

    const response = await fetchIntercept(
      `${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/reports/list`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${getAccessTokenFromStorage()}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          filters: {
            companies: filteredCompanies,
          },
          pagination: {
            current,
            size,
          },
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Response not ok');
    }

    const responseBody = await response.json();

    return {
      ...responseBody,
      list: responseBody.reports,
    };
  }
);

export const generateReport = debounce(async (filters) => {
  const {
    chargeback,
    companies,
    profile,
    status,
    selectedDatePeriod: [start, end],
  } = filters;

  let filteredCompanies;

  if (companies?.length) {
    filteredCompanies = companies;
  } else if (profile.selectedProfileType === PROFILE_TYPE_ORGANIZATION) {
    filteredCompanies = profile.selectedProfile.companies.map(({ id }) => id);
  } else if (profile.selectedProfile) {
    filteredCompanies = [profile.selectedProfile.id];
  } else return;

  const response = await fetchIntercept(
    `${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/reports`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${getAccessTokenFromStorage()}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        filters: {
          dates: {
            range: {
              start,
              end,
            },
          },
          chargeback,
          companies: filteredCompanies,
          status,
        },
      }),
    }
  );

  if (response.status === 204) {
    throw new Error('No content');
  }

  if (!response.ok) {
    throw new Error('Response not ok');
  }
});

export const resendReport = debounce(async (reportId) => {
  const response = await fetchIntercept(
    `${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/reports/resend/${reportId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${getAccessTokenFromStorage()}`,
        'content-type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Response not ok');
  }
});

export const getReportLink = debounce(async (reportId, fileId) => {
  const response = await fetchIntercept(
    `${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/reports/${reportId}/link?fileID=${fileId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${getAccessTokenFromStorage()}`,
        'content-type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return await response.json();
});
