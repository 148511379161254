export const trackEvent = async (eventName, eventData) => {
  try {
    return await analytics.track(eventName, {
      ...eventData
    });
  } catch (err) {
    throw new Error('Error track Segment');
  }
};

export default { trackEvent };
