import { debounce, fetchIntercept } from '@frontend/shared-utility';
import { getAccessTokenFromStorage, PROFILE_TYPE_ORGANIZATION } from '@frontend/shared-context';

export const getTransactions = debounce(async ({ pageSize: size, current }, { filters, search }) => {
  const { chargeback, companies, profile, status, selectedDatePeriod: [start, end] } = filters;

  let filteredCompanies;

  if (companies?.length) {
    filteredCompanies = companies;
  }
  else if (profile.selectedProfileType === PROFILE_TYPE_ORGANIZATION) {
    filteredCompanies = profile.selectedProfile.companies.map(({ id }) => id);
  }
  else if (profile.selectedProfile) {
    filteredCompanies = [profile.selectedProfile.id];
  }
  else return;

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/list`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      filters: {
        dates: {
          range: {
            start,
            end
          },
        },
        chargeback,
        companies: filteredCompanies,
        status,
      },
      search: search ?? '',
      pagination: {
        current,
        size,
      }
    })
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  const responseBody = await response.json();

  return {
    ...responseBody,
    list: responseBody.transactions,
  };
});

export const createTransaction = async ({ company, name, cpf, firstNumbers, lastNumbers, orderNumber, value }) => {

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/link`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      card: {
        binDigits: firstNumbers,
        lastDigits: lastNumbers,
        name,
      },
      company,
      identity: {
        key: "cpf",
        value: cpf
      },
      orderNumber,
      value,
    })
  });

  const result = await response.json();

  if (!response.ok) {
    throw new Error(result.error.code || 'Response not ok');
  }

  return result;
};

export const getTransactionDetails = async id => {

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/${id}`, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
    }
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return await response.json();
};

export const notify = async ({ email, phone, transactionId }) => {
  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/${transactionId}/notify`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      email,
      phone,
    })
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return await response.json();
};

export const createBatchTransactions = async ({ fileId, companyId }) => {
  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/batch`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      company: companyId,
      file: fileId
    })
  });

  if (!response.ok) {
    let error;

    try {
      return await response.json();
    }
    catch (e) {
      error = new Error('Response not ok');
    }

    throw error;
  }

  return await response.json();
};

export const confirmBatchTransactions = async batchId => {
  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/${process.env.NX_IDPAY_PRODUCT}/transactions/batch/${batchId}`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return await response.json();
};
