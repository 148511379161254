import ErrorTemplate from "../../components/error-template";
import { Link } from "@acesso-io/genoma-ui";
import { useTranslation } from '@frontend/feature-i18n';

export const Error404 = () => {
  const { getTranslation, getTranslationAsString } = useTranslation();

  return (
    <ErrorTemplate
      errorCode='404'
      title={getTranslationAsString('PageNotFound', {}, 'Ops, página não encontrada')}
    >
      <span>
      {getTranslation(
          "CheckUrlOrReturnToHomePage",
          null,
          {
            Link: <Link href="/" />,
          },
          "Verifique a URL ou retorne para a <Link href='/'>tela inicial.</Link>"
        )}
      </span>
    </ErrorTemplate>
  );
};

export default Error404;
