import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from "../icon";
import { Button, Typography, Menu, defaultTheme } from '@acesso-io/genoma-ui';
import { useTranslation as useTranslationI18n } from 'react-i18next';
import { Check, ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { ThemeProvider } from '@mui/material/styles';

const Languages = ({ trackEventChangeLanguage }) => {
  const [menuState, setMenuState] = useState({ anchorEl: null, open: false });
  const { i18n } = useTranslationI18n();

  const languageLabels = useMemo(() => [
    { key: "pt-BR", iconType: 'brazil', short: 'PT' },
    { key: "en-US", iconType: 'usa', short: 'EN' },
    { key: "es-MX", iconType: 'mex', short: 'ES' }
  ], []);

  const currentLanguageLabel = useMemo(() =>
    languageLabels.find(language => language.key === i18n.language),
    [i18n.language, languageLabels]
  );

  const handleMenuToggle = (event) => {
    setMenuState(prevState => ({
      anchorEl: event.currentTarget,
      open: !prevState.open
    }));
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, open: false });
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language.key);
    if (trackEventChangeLanguage) {
      trackEventChangeLanguage(language.key);
    }
    handleClose();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Button
        color="secondary"
        size="small"
        variant="outlined"
        onClick={handleMenuToggle}
        className='language-button'
        sx={{ paddingRight: '14px', paddingLeft: '14px' }}
      >
        <Icon type={currentLanguageLabel?.iconType} />
        <Typography sx={{ marginRight: '8px', fontWeight: '700' }}>
          &nbsp; {currentLanguageLabel?.short}
        </Typography>
        {menuState.open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Menu
        anchorEl={menuState.anchorEl}
        open={menuState.open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {languageLabels.map(language => (
          <Menu.Item key={language.key} onClick={() => handleChangeLanguage(language)} value={language.key}>
            <Icon type={language.iconType} />
            <Typography sx={{ marginRight: '12px', marginLeft: '12px' }}>
              {i18n.t(`chooseLanguage.${language.short}`)} ({language.short})
            </Typography>
            {language.short === currentLanguageLabel?.short && (
              <Check sx={{ width: '18px', height: '20px', marginLeft: '12px' }} />
            )}
          </Menu.Item>
        ))}
      </Menu>
    </ThemeProvider>
  );
};

Languages.propTypes = {
  trackEventChangeLanguage: PropTypes.func,
};

export default Languages;
