export { default as Upload } from './component';
export { default as FakeUpload } from './component/fake-upload';

export { useUpload } from './hook';
export { UploadProvider } from './context';

export {
  UPLOAD_STATUS_FAIL,
  UPLOAD_STATUS_LOADING,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_WAITING,
  UPLOAD_STATUS_WARNING
} from './defaults/status';

export {
  FILE_EXTENSION_PDF,
  FILE_EXTENSION_PNG,
  default as FILE_TYPES,
} from './defaults/file-types';

export {
  UPLOAD_TYPE_IMAGE,
  UPLOAD_TYPE_DOC,
} from './defaults/upload-types';

