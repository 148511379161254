import { Suspense, useCallback, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Icon, Input, Layout, LoadingScreen, notification, NotFound } from '@frontend/shared-ui'
import { FiltersProvider, FilterByDateProvider, AuthPkceProvider, useAuthPkce, useProfiles, ProfilesProvider, PERMISSION_MANAGE_SETTINGS, TransactionSearchProvider, useTransactionSearch } from '@frontend/shared-context';
import { DEFAULT_HOME_ROUTE, DEFAULT_ROUTE, ROUTE_SETTINGS, ROUTE_TRANSACTIONS, getRoutesHandlers } from './defaults/routes';
import { useTranslation } from '@frontend/feature-i18n';
import { PROFILE_TYPE_COMPANY } from 'libs/shared-context/src/lib/context/defaults/profile-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { logoutPkce } from 'libs/shared-context/src/lib/defaults/auth-control';

notification.config({ top: 80 });

export const App = () => {
  const { getTranslation } = useTranslation();

  const transactionSearch = useTransactionSearch(ROUTE_TRANSACTIONS);

  const { accessGranted } = useAuthPkce();
  const { hasPermissionInSomeCompany, selectedProfileType } = useProfiles();
  const { enableSettingsScreen } = useFlags();
  const { pathname: currentLocation } = useLocation();
  const navigate = useNavigate();

  const isSelectedRoute = route => currentLocation === route;

  const handleOnInputSearch = useCallback(evt => {
    const { value } = evt.target;
    transactionSearch.set(value);
  }, [transactionSearch]);

  const transactionsScreenFooterRightContent = useMemo(() => (
    transactionSearch.lastSearch
      ? <div>Última atualização em {transactionSearch.lastSearch}</div>
      : null
  ), [transactionSearch]);

  const disabledRoutes = useMemo(() => ({
    [ROUTE_SETTINGS]: !enableSettingsScreen || selectedProfileType !== PROFILE_TYPE_COMPANY || !hasPermissionInSomeCompany(PERMISSION_MANAGE_SETTINGS),
  }), [enableSettingsScreen, hasPermissionInSomeCompany, selectedProfileType]);

  const enabledRoutes = useMemo(
    () =>
      getRoutesHandlers(getTranslation).filter(
        ({ route }) => !disabledRoutes[route]
      ),
    [disabledRoutes, getTranslation]
  );

  const visibleEnabledRoutes = useMemo(() =>
    enabledRoutes
      .filter(({ menuItem, invisible }) => menuItem && !invisible)
    , [enabledRoutes]);

  return (
    <Layout
      footerSettings={{
        rightContent:
          isSelectedRoute(ROUTE_TRANSACTIONS)
            ? transactionsScreenFooterRightContent
            : null
      }}
      sidebarSettings={{
        items:
          visibleEnabledRoutes
            .map(
              ({ iconType, route, title }) => ({
                icon: <Icon type={iconType} />,
                onClick: () => navigate(route),
                selected: isSelectedRoute(route),
                title,
              })
            ),
        showCompanyKinds: true,
        logout: logoutPkce,
        title: null
      }}
      topbarSettings={{
        usePkce: true,
        leftContent:
          <Input
            data-testid="input-search"
            prefix={<Icon type="search" />}
            placeholder="Processo ou CPF"
            onInput={handleOnInputSearch}
            style={{
              boxShadow: '0px 1px 3px #1315161a, 0px 1px 2px #1315160f',
              height: '40px',
              width: '270px',
            }}
            value={transactionSearch.search} />,
      }}>
      <FiltersProvider>
        <FilterByDateProvider>
          {
            accessGranted
              ? (
                <Suspense fallback={<LoadingScreen />}>
                  <Routes>
                    <Route path={DEFAULT_ROUTE} element={<Navigate to={DEFAULT_HOME_ROUTE} replace={true} />} />
                    {
                      enabledRoutes.map(
                        ({ Component, route }) =>
                          <Route key={route} path={route} element={<Component />} />
                      )
                    }
                    <Route path="*" element={<NotFound defaultRoute={DEFAULT_ROUTE} disabledRoutes={disabledRoutes} />} />
                  </Routes>
                </Suspense>
              )
              : <LoadingScreen />
          }
        </FilterByDateProvider>
      </FiltersProvider>
    </Layout>
  );
}

const Enhanced = () => (
  <AuthPkceProvider >
    <ProfilesProvider authPkce={true}>
      <TransactionSearchProvider>
        <App />
      </TransactionSearchProvider>
    </ProfilesProvider>
  </AuthPkceProvider>
);

export default Enhanced;
