import { debounce, fetchIntercept } from "@frontend/shared-utility";
import { getAccessTokenFromStorage } from "@frontend/shared-context";

export const getSettings = async companyId => {

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/companies/${companyId}/settings`, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
    }
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return await response.json();
};

export const updateCompanySettings = debounce(async (companyId, settings) => {

  const { kind, fileId: logo, description, integrationModel } = settings;

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/companies/${companyId}/settings`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      description,
      kind,
      logo,
      integrationModel,
    })
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }
});

export const updateMessageSettings = debounce(async (companyId, settings) => {

  const {
    frequency: duration,
    maxMessages: resend,
    logoId,
    linkPrefix,
    startTime: start,
    endTime: end,
    mobileTypeNotification,
    expirationTime,
    steps,
  } = settings;

  const response = await fetchIntercept(`${process.env.NX_IDPAY_ENDPOINT}/api/v1/companies/${companyId}/settings/template`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${getAccessTokenFromStorage()}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      logoId,
      linkPrefix,
      expirationTime,
      notification: {
        mobileTypeNotification,
        duration,
        resend,
        interval: {
          start: start ?? null,
          end: end ?? null
        }
      },
      steps
    })
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }
});